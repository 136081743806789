<template>
  <b-modal
    id="modal-pay-method-select"
    :title="title"
    no-stacking
    footer-class="d-flex justify-content-center align-items-center"
    body-class="bg-light rounded"
  >
    <div class="card card-custom mb-2 bg-diagonal">
      <div class="card-body">
        <div class="d-flex align-items-center justify-content-between flex-lg-wrap flex-xl-nowrap">
          <div class="d-flex flex-column mr-5">
            <a href="#" class="h4 text-dark text-hover-primary mb-5">
              {{ selectedInvoice.type.category.name }} #{{ selectedInvoice.id }}
            </a>
            <p class="text-dark-50">{{ selectedInvoice.type.name }}</p>
          </div>
          <div class="ml-6 ml-lg-0 ml-xxl-6 flex-shrink-0">
            <a
              href="/metronic/demo2/custom/apps/support-center/feedback.html"
              target="_blank"
              class="btn font-weight-bolder text-uppercase btn-light-primary py-4 px-6"
            >
              {{ selectedInvoice.final_amount | currency() }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <b-form class="form mt-5" @submit.stop.prevent="onSubmit">
      <div v-if="!isLoading">
        <b-form-group v-for="gateway in invoiceGateways" :key="gateway.id">
          <h4 class="mb-5">Selecione o método de pagamento</h4>
          <!-- <span class="text-success font-weight-bold">{{ gateway.description }}</span> -->
          <div class="custom-radio-square">
            <div v-for="method in gateway.methods" :key="method.id" class="radio-tile-group">
              <div class="input-container">
                <input
                  id="walk"
                  v-model="$v.form.method.$model"
                  class="radio-button"
                  type="radio"
                  name="method"
                  :value="method.id"
                  :state="validateState('method')"
                />
                <div class="radio-tile">
                  <span class="icon">
                    <span class="svg-icon svg-icon-dark svg-icon-3x">
                      <inline-svg :src="'/media/svg/icons/Shopping/' + method.icon + '.svg'" />
                      <!--end::Svg Icon-->
                    </span>
                  </span>
                  <label for="walk" class="radio-tile-label">{{ method.name }}</label>
                </div>
              </div>
            </div>
          </div>
          <b-form-invalid-feedback :style="!$v.form.method.$model ? 'display:block' : ''">
            O campo bairro é obrigatório.
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
      <div v-else class="font-size-xs text-dark mb-6 d-flex align-items-center">
        <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
        Carregando Gateways..
      </div>
    </b-form>

    <template #modal-footer>
      <button
        ref="btn_submit"
        type="submit"
        class="btn btn-success font-weight-bold px-12 py-4 my-3 font-size-3 mx-4"
        :disabled="isSubmitting"
        @click="onSubmit"
      >
        Efetuar Pagamento
      </button>
    </template>
  </b-modal>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

export default {
  name: 'ModalPayMethodSelect',
  mixins: [validationMixin],
  props: {
    title: {
      type: String,
      default: 'Selecione o método de pagamento'
    }
  },
  data() {
    return {
      isLoading: false,
      isSubmitting: false,
      form: {
        method: 1
      }
    };
  },
  computed: {
    ...mapGetters(['invoiceGateways', 'selectedInvoice'])
  },
  validations: {
    form: {
      method: {
        required
      }
    }
  },
  async mounted() {
    this.isLoading = true;
    await this.$store.dispatch('GET_INVOICE_PARAMS');
    this.isLoading = false;
  },
  methods: {
    async onSubmit() {
      this.isSubmitting = true;

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.isSubmitting = false;
        return;
      }

      const form = {
        method_id: this.form.method,
        invoice_id: this.selectedInvoice.id
      };

      const submitButton = this.$refs['btn_submit'];
      submitButton.classList.add('spinner', 'spinner-light', 'spinner-right');

      // send register request
      await this.$store
        .dispatch('PAY_INVOICE_SELECT_METHOD', form)
        .then(() => {
          this.$bvModal.show('modal-pay-invoice');

          this.isSubmitting = false;
          submitButton.classList.remove('spinner', 'spinner-light', 'spinner-right');

          this.resetForm();
        })
        .catch(() => {
          this.isSubmitting = false;
          submitButton.classList.remove('spinner', 'spinner-light', 'spinner-right');
        });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    }
  }
};
</script>
