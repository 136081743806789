<template>
  <b-modal
    id="modal-pay-invoice"
    :title="'Pagamento de ' + selectedInvoice.type.category.name"
    size="lg"
    hide-footer
    no-stacking
    footer-class="d-flex justify-content-center align-items-center"
    body-class="bg-light rounded"
  >
    <OverlayLoader :is-submitting="loader.show" :message="loader.message" :timing="loader.timing">
      <div
        class="alert alert-custom alert-outline-2x alert-outline-primary fade show mb-5"
        :class="'alert-' + selectedInvoice.status.class"
        role="alert"
      >
        <div class="alert-icon"><i class="flaticon-warning"></i></div>
        <div class="alert-text">{{ selectedInvoice.status.description }}</div>
      </div>

      <div class="row">
        <div class="col-lg-6 d-flex align-items-center justify-content-center order-2 flex-column">
          <span class="svg-icon svg-icon-8x">
            <inline-svg src="/media/misc/pix-banco-central-logo.svg" />
          </span>
          <div class="w-100 mt-n10 text-center">
            <qrcode-vue
              class="qrCodePix m-sm-10 m-10 text-info"
              :value="selectedInvoiceTransaction.checkout_url"
              level="H"
              render-as="svg"
              size="100"
              foreground="#2e9e01"
            ></qrcode-vue>
            <div class="text-center">
              <h5>Essa transação PIX expira em:</h5>
              <countdown
                v-slot="{ minutes, seconds }"
                :time="$moment(selectedInvoiceTransaction.expiration).valueOf() - $moment().valueOf()"
                :interval="100"
              >
                <h1 class="text-info">{{ minutes }}:{{ seconds }}</h1>
              </countdown>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <!--begin::Card-->
          <div class="card card-custom">
            <div class="card-header d-flex align-items-center">
              <h3 class="card-title font-weight-bolder text-dark font-size-h2">
                {{ selectedInvoice.type.category.name }}
              </h3>
              <div class="card-toolbar text-success d-flex align-items-center">#{{ selectedInvoice.id }}</div>
            </div>

            <div class="card-header d-flex align-items-center" style="min-height: 35px">
              <span class="text-muted font-weight-bold font-size-sm">{{ selectedInvoice.type.name }}</span>
            </div>

            <!--begin::Card Body-->
            <div class="card-body">
              <div class="text-success font-weight-bold display-3 font-size-h1 mb-5 text-center">
                {{ selectedInvoiceTransaction.final_amount | currency() }}
              </div>
              <div class="font-size-sm mb-8">
                Acesse o site do seu banco e faça o pix para a chave acima.
                <br />
                <br />
                É imprescindível que você deposite exatamente o valor considerando os centavos.
                <br />
                <br />
                Não serão aceitos transferências de terceiros e depósitos em dinheiro.
                <br />
                <br />
                Transferências eletrônicas divergentes das instruções indicadas acima poderão ser retidas até
                comprovação do remitente.
              </div>
              <!--begin::Info-->

              <ul class="posts-holder">
                <li>
                  <div class="title">Chave Pix</div>
                  <div class="title-holder">
                    <div class="value">
                      {{ selectedInvoiceTransaction.checkout_url }}
                    </div>
                    <a
                      v-clipboard:copy="selectedInvoiceTransaction.checkout_url"
                      v-clipboard:success="onCopy"
                      v-clipboard:error="onError"
                      href="javascript:void(0);"
                      class="btn btn-icon btn-info btn-xs"
                    >
                      <i class="far fa-copy"></i>
                    </a>
                  </div>
                </li>
                <li>
                  <div class="title">Identificaçõo extrato</div>
                  <div class="title-holder">
                    <div id="safepagId" class="value">1232518</div>
                    <a href="javascript:void(0);" class="file-ico copy js-copy" data-clipboard-target="#safepagId">
                      <i class="far fa-copy"></i>
                    </a>
                  </div>
                </li>
              </ul>

              <!--end::Info-->
            </div>
            <!--end::Card Body-->
          </div>
          <!--end::Card-->
        </div>
      </div>
    </OverlayLoader>
  </b-modal>
</template>

<script>
import OverlayLoader from '@/shared/content/OverlayLoader.vue';

import QrcodeVue from 'qrcode.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'NewDeposit',
  components: { OverlayLoader, QrcodeVue },
  data() {
    return {
      isSubmitting: false,
      isLoading: false,
      loader: {
        show: false,
        message: '',
        timing: false
      }
    };
  },
  computed: {
    ...mapGetters(['selectedInvoice', 'selectedInvoiceTransaction'])
  },
  methods: {
    onCopy: function (e) {
      this.$swal.fire({
        toast: true,
        icon: 'success',
        timer: 3000,
        showConfirmButton: false,
        target: '.js-copy',
        position: 'bottom',
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', this.$swal.stopTimer);
          toast.addEventListener('mouseleave', this.$swal.resumeTimer);
        },
        title: 'Chave PIX copiada com sucesso!'
      });
    },
    onError: function (e) {
      alert('Failed to copy texts');
    }
  }
};
</script>
