<template>
  <div>
    <div v-if="invoice.status_id == 1">
      <a :ref="'btn_pay_' + invoice.id" class="btn btn-info btn-sm font-weight-bolder mr-2" @click="pay(invoice.id)">
        <span class="svg-icon svg-icon-md svg-icon-white">
          <inline-svg src="/media/svg/icons/Shopping/Money.svg" />
        </span>
        Pagar
      </a>
      <a
        v-if="invoice.ecosystem_invoice_type_id !== 1"
        :ref="'btn_cancel_' + invoice.id"
        class="btn btn-danger btn-sm font-weight-bolder"
        @click="cancel(invoice.id)"
      >
        <span class="svg-icon svg-icon-md svg-icon-white">
          <inline-svg src="/media/svg/icons/General/Trash.svg" />
        </span>
        Cancelar
      </a>
    </div>
    <div v-else-if="invoice.status_id == 2">
      <a :ref="'btn_receipt_' + invoice.id" class="btn btn-info btn-sm font-weight-bolder" @click="receipt(invoice.id)">
        <span class="svg-icon svg-icon-md svg-icon-white">
          <inline-svg src="/media/svg/icons/Code/Info-circle.svg" />
        </span>
        Comprovante
      </a>
    </div>
    <div v-else>--</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'InvoiceActions',
  components: {},
  props: {
    invoice: {
      type: Object,
      default: null
    }
  },
  computed: {
    // ...mapGetters(['depositSelectedInvoice'])
  },
  methods: {
    async pay(invoice_id) {
      const btn_pay = this.$refs['btn_pay_' + invoice_id];
      btn_pay.classList.add('spinner', 'spinner-light', 'spinner-right');

      await this.$store
        .dispatch('PAY_INVOICE', invoice_id)
        .then((response) => {
          if (response.transaction == null) {
            this.$bvModal.show('modal-pay-method-select');
          } else {
            this.$bvModal.show('modal-pay-invoice');
          }
        })
        .catch(() => {});

      btn_pay.classList.remove('spinner', 'spinner-light', 'spinner-right');
    },
    async receipt(invoice_id) {
      const btn_receipt = this.$refs['btn_receipt_' + invoice_id];
      btn_receipt.classList.add('spinner', 'spinner-light', 'spinner-right');

      await this.$store
        .dispatch('GET_INVOICE_RECEIPT', invoice_id)
        .then(() => {
          this.$bvModal.show('modal-receipt-invoice');
        })
        .catch(() => {});

      btn_receipt.classList.remove('spinner', 'spinner-light', 'spinner-right');
    },
    async cancel(invoice_id) {
      this.$swal({
        title: 'Você tem certeza que deseja cancelar este depósito ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Sim, cancelar!',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$swal({
            text: 'Aguarde enquanto cancelamos o depósito...',
            icon: 'warning',
            showConfirmButton: false,
            dangerMode: true,
            allowOutsideClick: false
          });

          await this.$store
            .dispatch('CANCEL_INVOICE', invoice_id)
            .then((response) => {
              this.$swal({ title: response.success, icon: 'success' });
              this.$store.dispatch('GET_DEPOSIT_LIST', { params: { status_id: '1,2' } });
              this.$store.dispatch('GET_INVOICE_PARAMS');
            })
            .catch((error) => {
              this.$swal({
                title: error,
                icon: 'error'
              });
            });
        }
      });
    }
  }
};
</script>

<style></style>
