import ModalPayInvoice from '@/shared/components/invoice/ModalPayInvoice';
import ModalPayMethodSelect from '@/shared/components/invoice/ModalPayMethodSelect';
import ModalReceipt from '@/shared/components/invoice/ModalReceipt';

const invoiceModalsMixin = {
  components: {
    ModalPayInvoice,
    ModalReceipt,
    ModalPayMethodSelect
  }
};

export default invoiceModalsMixin;
