<template>
  <b-modal
    id="modal-receipt-invoice"
    title="Comprovante de Depósito"
    modal
    hide-footer
    footer-class="d-flex justify-content-center align-items-center"
    body-class="bg-light rounded p-0"
  >
    <div class="card card-custom position-relative overflow-hidden" style="border-radius: 0px 0 5px 5px">
      <!--begin::Invoice header-->
      <div class="row justify-content-center align-items-center py-10 bg-dark">
        <div class="col-md-10">
          <div class="d-flex justify-content-center flex-column flex-md-row">
            <div class="d-flex flex-column align-items-center">
              <!--begin::Logo-->
              <a href="#" class="mb-5 max-w-150px">
                <span class="svg-icon svg-icon-full svg-logo-white">
                  <!--begin::Svg Icon | path:/metronic/theme/html/demo2/dist/assets/media/svg/logos/duolingo.svg-->
                  <img src="/media/logos/logo-with-name-white.png" width="100%" />
                  <!--end::Svg Icon-->
                </span>
              </a>
              <!--end::Logo-->
              <h1 class="display-5 font-weight-boldest text-white order-1 order-md-2">
                {{ selectedInvoiceReceipt.invoice.type.category.name }} : {{ selectedInvoiceReceipt.invoice.id }}
              </h1>
              <h4 class="display-5 font-weight-boldest text-white order-1 order-md-2">
                {{ selectedInvoiceReceipt.invoice.type.name }}
              </h4>
              <span
                class="d-flex justify-content-center flex-column text-center font-size-h5 font-weight-bold order-3 text-white"
              >
                <span>
                  Valor:
                  <b>{{ selectedInvoiceReceipt.invoice.final_amount | currency() }}</b>
                </span>
                <span>
                  Tipo de transaferência:
                  <b>PIX</b>
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>

      <!--end::DESTINO RECEIPT-->
      <div class="row justify-content-center bg-light">
        <h3 class="display-8 font-weight-boldest text-info py-5 text-center w-100 bg-light-primary">Destino</h3>
        <div class="col-md-11">
          <div class="table-responsive">
            <table class="table table-borderless">
              <tbody>
                <tr class="font-weight-bolder">
                  <td class="border-top-1">Nome</td>
                  <td class="text-right text-info">Snailbank Snailb - Solucoes Financeiras Para Longevidade LTDA</td>
                </tr>
                <tr class="font-weight-bolder border-top">
                  <td class="border-top-1">CNPJ</td>
                  <td class="text-right text-info">39.601.858/0001-47</td>
                </tr>
                <tr class="font-weight-bolder border-top">
                  <td class="border-top-1">Chave PIX</td>
                  <td class="text-right text-info">bc525f0f-ed2b-40a1-b9c8-f7f8902a6056</td>
                </tr>
                <tr class="font-weight-bolder border-top">
                  <td class="border-top-1">Instituição</td>
                  <td class="text-right text-info">GerenciaNET</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!--end::DADOS RECEIPT-->
      <div class="row justify-content-center bg-light">
        <h3 class="display-8 font-weight-boldest text-info py-5 text-center w-100 bg-light-primary">
          Dados gerais do pagamento
        </h3>

        <div class="col-md-11">
          <div class="table-responsive">
            <table class="table table-borderless">
              <tbody>
                <tr class="font-weight-bolder">
                  <td class="border-top-1">Indentificador</td>
                  <td class="text-right text-info">{{ selectedInvoiceReceipt.invoice.transaction.pix_txid }}</td>
                </tr>
                <tr class="font-weight-bolder border-top">
                  <td class="border-top-1">Criação</td>
                  <td class="text-right text-info">
                    {{ formatDateBrl(selectedInvoiceReceipt.invoice.transaction.payment_confirmation) }}
                  </td>
                </tr>
                <tr class="font-weight-bolder border-top">
                  <td class="border-top-1">Confirmado</td>
                  <td class="text-right text-info">
                    {{ formatDateBrl(selectedInvoiceReceipt.invoice.transaction.payment_confirmation) }}
                  </td>
                </tr>
                <tr class="font-weight-bolder border-top">
                  <td class="border-top-1">Nome do pagador</td>
                  <td class="text-right text-info">{{ currentCustomer.name }}</td>
                </tr>
                <tr class="font-weight-bolder border-top">
                  <td class="border-top-1">CPF do pagador</td>
                  <td class="text-right text-info">{{ currentCustomer.code }}</td>
                </tr>
                <tr class="font-weight-bolder border-top">
                  <td class="border-top-1">Descrição</td>
                  <td class="text-right text-info">Snailbank</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!--end::TRANSAÇÃO RECEIPT-->
      <div class="row justify-content-center bg-dark text-center">
        <div class="col-md-10 px-5">
          <h3 class="display-8 font-weight-boldest text-white pt-5">ID da transação</h3>
          <h3 class="display-8 font-weight-boldest text-warning pb-5">
            {{ selectedInvoiceReceipt.invoice.transaction.pix_e2eid }}
          </h3>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ModalReceiptInvoice',
  computed: {
    ...mapGetters(['selectedInvoiceReceipt', 'currentCustomer'])
  },
  methods: {
    print() {}
  }
};
</script>
